<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false">
      <b-row>
        <b-col cols="12" class="p-0">
          <div v-if="!teacher.doc_cover" class="cover-profile-teacher img-fluid rounded d-block">
            <div class="caption">
              <div class="inner_caption">
                <div class="align_caption">
                  <h2 class="title section-header-title text-uppercase">{{ $t('WORLD YOGA ALLIANCE') }}</h2>
                  <p class="subtitle text-primary-dark mt-3">{{ $t('UNITY IN DIVERSITY') }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <img :src="teacher.doc_cover" class="profile-cover-image rounded img-fluid" @error="$event.target.src=PUBLIC_PATH+'img/cover/header_teacherdirectory.png'"/>
          </div>
        </b-col>
        <b-col class="w-100" style="z-index:100">
          <b-button id="pick-cover" style="z-index:100" pill v-bind:class="[ownerTeacher ? activeClassCover : defaultClassCover]"><b-icon icon="camera" class="mr-2" variant="light"></b-icon> {{ $t('Edit cover') }}</b-button>
          <avatar-cropper
            trigger="#pick-cover"
            :upload-url="urlUploadCover"
            @uploading="handleUploading"
            @completed="handleCompleted"
            @error="handlerError"
            :cropper-options="{ minContainerWidth: 1140, minContainerWidth: 320 }"
            :output-options="{ width:1140, height:320}"
            :output-quality="0.7"
            :upload-headers="uploadHeader"
            requestMethod="POST"
            :labels="{ submit: $t('Upload'), cancel: $t('Cancel')}"
            mimes="image/png, image/gif, image/jpeg"
            upload-form-name="doc_cover"
            />
        </b-col>
      </b-row>
      <b-row class="pt-4">
        <b-col lg="3" md="3" sm="12" class="container-avatar flex-justify-content-center">
          <div>
            <b-avatar v-if="teacher.doc_avatar" variant="secondary" class="avatar-profile" :src="teacher.doc_avatar"></b-avatar>
            <b-avatar v-else variant="secondary" class="avatar-profile" :src="PUBLIC_PATH+'img/icons/android-chrome-512x512.png'"></b-avatar>
          </div>
        </b-col>
        <b-icon style="height:40px; width:40px;margin-left: -100px; z-index: 100; margin-top:10px" icon="camera" id="pick-avatar" v-bind:class="[ownerTeacher ? activeClassAvatar : defaultClassAvatar]" variant="light"></b-icon>
        <avatar-cropper
          trigger="#pick-avatar"
          :upload-url="urlUploadAvatar"
          @uploading="handleUploading"
          @completed="handleCompleted"
          @error="handlerError"
          :output-options="{ width:512, height:512}"
          :output-quality="0.7"
          :upload-headers="uploadHeader"
          requestMethod="POST"
          :labels="{ submit: $t('Upload'), cancel: $t('Cancel')}"
          mimes="image/png, image/gif, image/jpeg"
          upload-form-name="doc_avatar"
          />
        <b-col lg="6" md="9" sm="12" class="text-center">
          <h2 class="text-primary-dark content-header-title text-md-left text-lg-left d-sm-block">{{ teacher.name  }}</h2>
          <b-row class="mt-3">
            <b-col lg="6" md="6" sm="12" class="text-center pr-0">
              <h5 class="text-gray-light content-title text-md-left text-lg-left font-weight-400">{{ $t('WYA Teacher ID') }} : <span class="text-primary-dark">{{  (teacher.teacher_id || '') }}</span></h5>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="text-center pr-0">
              <h5 class="text-gray-light content-title text-md-left text-lg-left font-weight-400">{{ $t('Member Since') }} : <span class="text-primary-dark">{{ new Date(teacher.member_since) | dateFormat('DD MMMM YYYY') }}</span></h5>
            </b-col>
          </b-row>
           <b-row class="">
            <b-col lg="6" md="6" sm="12" class="text-center pr-0">
              <h5 class="text-gray-light content-title text-md-left text-lg-left font-weight-400">{{ $t('Valid Until') }} :
                <span v-if="teacher.expired_date" class="text-primary-dark">{{ new Date(teacher.expired_date) | dateFormat('DD MMMM YYYY') }}</span>
                <span v-else class="text-primary-dark">{{ $t('Non-Expiring License') }}</span>
              </h5>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="text-center pr-0">
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3" md="12"  sm="12" class="">
          <UpcomingEvents :groups="['teacher', 'cyt', 'annual', 'all']" />
          <b-col class="yoga-stat text-center d-flex d-lg-none mt-4 mb-4">
            <!-- <b-col cols="3" class="">
              <h4 class="stat-value text-primary-dark font-weight-600">-</h4>
              <span class="text-gray-light text-13">{{ $t('Viewers') }}</span>
            </b-col>
            <b-col cols="3">
              <h4 class="stat-value text-primary-dark font-weight-600">-</h4>
              <span class="text-gray-light text-13">{{ $t('Event') }}</span>
            </b-col>
            <b-col cols="3">
              <h4 class="stat-value text-primary-dark font-weight-600">-</h4>
              <span class="text-gray-light text-13">{{ $t('Graduates') }}</span>
            </b-col> -->
            <b-col cols="12">
              <b-button v-b-toggle.sidebar-1 pill variant="light" class="more-button btn-outline-default"><font-awesome-icon :icon="['fas', 'ellipsis-v']" /><span class="d-none">{{ $t('More')}}</span></b-button>
              <span class="d-block text-gray-light text-13" style="margin-top: 11px;">{{ $t('More') }}</span>
              <b-sidebar id="sidebar-1" backdrop>
                <div class="text-left py-2 bg-white">
                  <b-col cols="12">
                  <b-col cols="12" class=""><span class="text-primary-dark font-weight-bold content-title">{{ $t('Status') }}</span></b-col>
                  <div class="row mt-2 pl-4">
                    <div class="d-inline-flex mb-2" v-for="teacher_apply_type in teacher.teacher_apply_type" :key="teacher_apply_type.id">
                      <div class="d-flex align-items-center justify-content-center pl-0 pr-0">
                        <b-avatar variant="light" size="3.0rem" :src="PUBLIC_PATH+'img/certificate/' + teacher_apply_type.teacher_apply_type_code + '.png'"></b-avatar>
                      </div>
                      <div  class="d-flex pl-2 pr-0">
                        <span class="small text-primary-dark list-style-type-none">{{ teacher_apply_type.teacher_apply_type_name }}</span>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-col cols="12" class="mt-4"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Level of registration') }}</span></b-col>
                  <ul>
                    <li class="small text-primary-dark" style="text-transform: capitalize;" v-for="teacher_level in teacher.teacher_level" :key="teacher_level.id">
                      {{ teacher_level.teacher_level_name }}
                    </li>
                  </ul>
                </b-col>
                <!-- <b-col cols="12">
                    <b-col cols="12" class="mt-4"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Instructors') }}</span></b-col>
                </b-col>
                <b-col class="row mt-2">
                    <b-col cols="4" md="2" lg="4" class="d-flex align-items-center justify-content-center">
                      <b-avatar variant="light" size="3.8rem" class="badge-light"></b-avatar>
                      <b-avatar variant="info" size="1.5rem" style="position:absolute;top:40px;left:67px" :src="PUBLIC_PATH+'img/certificate/RTY.png'"></b-avatar>
                    </b-col>
                    <b-col cols="8" class="pl-0">
                      <span class="text-13 text-danger-light d-none">ITALY</span><br>
                      <span class="text-13 text-primary-dark font-weight-bold">instructors_name</span><br>
                      <span class="text-12 text-primary-dark d-none"><span class="text-12 text-gray-light">Member Since :</span> 8 May 2019</span>
                    </b-col>
                </b-col> -->
                <b-col class="mt-5">
                  <template v-if="loggedIn">
                      <b-col cols="12" class="mt-1"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Contact') }}</span></b-col>
                      <b-col cols="12">
                        <a v-if="teacher.website_url" :href="teacher.website_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-link border-0 mt-2"><font-awesome-icon :icon="['fas', 'link']" /></b-button></a>
                        <a v-if="teacher.facebook_url" :href="teacher.facebook_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-facebook border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'facebook-f']"/></b-button></a>
                        <a v-if="teacher.instagram_url" :href="teacher.instagram_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-instragram border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'instagram']"/></b-button></a>
                        <a v-if="teacher.twitter_url" :href="teacher.twitter_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-twitter border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'twitter']"/></b-button></a>
                      </b-col>
                      <b-col cols="12" class="pt-3">
                        <div><a :href="'tel:' + teacher.phone_number" class="small text-primary-dark d-inline-block" v-if="teacher.phone_number"><b-icon icon="telephone-fill" /> {{ teacher.phone_number }}</a></div>
                        <div><a :href="'mailto:' + teacher.email" class="small text-primary-dark d-inline-block" v-if="teacher.email"><b-icon icon="envelope-fill" /> {{ teacher.email }}</a></div>
                      </b-col>
                  </template>
                  </b-col>
                  <b-col class="mt-5">
                    <b-col cols="12"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Location') }}</span></b-col>
                    <b-col class="mt-3" v-if="(teacher.latitude && teacher.latitude) || (location.lat && location.lng )" cols="12 mt-2">
                      <GmapMap
                        :options="{
                          zoomControl: true,
                          mapTypeControl: false,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                          disableDefaultUi: false
                        }"
                        :center="{ lat: parseFloat(teacher.latitude || location.lat), lng: parseFloat(teacher.longitude || location.lng) }"
                        :zoom="zoom"
                        style="width:100%;  height: 200px;"
                      >
                      <GmapMarker
                        :position="{ lat: parseFloat(teacher.latitude || location.lat), lng: parseFloat(teacher.longitude || location.lng) }"
                        :clickable="true"
                        @click="center={ lat: parseFloat(teacher.latitude || location.lat), lng: parseFloat(teacher.longitude || location.lng) }"
                      ></GmapMarker>
                      </GmapMap>
                    </b-col>
                    <b-col v-if="teacher.address || teacher.region" cols="12" class="mt-3">
                      <b-row>
                        <b-col class="" cols="2">
                          <span class="text-danger-light"><font-awesome-icon :icon="['fas', 'map-marker-alt']" /></span>
                        </b-col>
                        <b-col class="pl-0" cols="10">
                          <span class="text-13 text-primary-dark">{{ teacher.address || teacher.region }} </span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-col>
                  <b-col class="col-12 mt-5">
                    <b-col cols="12" class="mt-1">
                      <span class="text-primary-dark font-weight-bold content-title">{{ $t('Image Gallery') }}</span><br>
                    </b-col>
                    <b-col cols="12 pt-2 pr-2 mb-4">
                      <b-row class="pr-3">
                        <b-col cols="12">
                          <CoolLightBox
                            :items="teacherGallerySlide"
                            :index="indexImg1"
                            @on-open="closeModalPreviewImg"
                            @close="indexImg1 = null">
                          </CoolLightBox>
                          <div class="images-wrapper row w-100">
                            <div class="col-4" v-for="(image, imageIndex) in teacherGallerySlide" :key="imageIndex">
                              <div
                                class="image"
                                @click="indexImg1 = imageIndex"
                                :style="{ backgroundImage: 'url(' + image.thumb + ')'}"
                              >
                              <b-dropdown style="z-index:100" v-if="userProfile && userProfile.id == teacher.user" size="sm" variant="light" right no-caret class="btn-edit-gallery float-right p-0">
                                <template #button-content>
                                  <b-icon icon="three-dots-vertical" font-scale="1" class="p-0"></b-icon>
                                </template>
                                <b-dropdown-item style="z-index:100" class="text-14" @click="setDataEditImg(image), $bvModal.show('modal-edit-img-gallery')"><b-icon icon="pencil" class="mt-n1 text-13"></b-icon> {{ $t('Edit') }}</b-dropdown-item>
                                <b-dropdown-item style="z-index:100" class="text-14" @click="onDeleteImgGallery(image), $bvModal.show('modal-delete-img-gallery')"><b-icon icon="trash" class="mt-n1 text-13"></b-icon> {{ $t('Delete') }}</b-dropdown-item>
                              </b-dropdown>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <div v-if="teacherGallerySlide.length == 0 && !userProfile" class="text-left col">
                          <span class="text-14">{{ $t('No results') }}</span>
                        </div>
                        <b-col v-if="userProfile && userProfile.id == teacher.user && teacherGallerySlide.length < 15" cols="4" class="pr-0">
                          <div v-b-modal.modal-add-img-gallery class="border border-1 rounded w-100 d-flex justify-content-center align-items-center add-img-gallery">
                            <b-icon scale="2" :title="$t('Add image gallery')" icon="plus"/>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-col>
                </div>
              </b-sidebar>
            </b-col>
          </b-col>
          <div v-if="!ownerTeacher" class="flex-justify-content-space-around flex-wrap-wrap">
              <div>
                <b-button id="popover-share-button-sync" pill class="mt-2 px-4 px-md-5 px-lg-5 px-xl-3 py-2 font-weight-bold btn-primary-dark d-none"><b-icon icon="reply-fill" class="mr-2"></b-icon>{{ $t('Share') }}</b-button>
              </div>
              <div class="d-none">
                <b-button pill class="mt-2 px-4 px-md-5 px-lg-5 px-xl-3 py-2 ml-0 ml-xl-3 font-weight-bold btn-danger-light"><b-icon icon="heart-fill" class="mr-3"></b-icon>{{ $t('Like') }}</b-button>
              </div>
              <b-popover boundary-padding="10" placement="auto" target="popover-share-button-sync" triggers="click blur">
                <div>
                  <p>SHARE VIA</p>
                  <hr/>
                  <social-sharing :id="viewTeacherId"/>
                </div>
              </b-popover>
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-5 pt-lg-3 pt-xl-4 profile-main-wrap">
        <b-col lg="3" class="d-none d-lg-block">
          <!-- <b-row class="yoga-stat text-center d-none d-lg-flex">
            <b-col cols="4" class="pl-1">
              <h4 class="text-primary-dark font-weight-600">{{ teacher.viewer | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Viewers') }}</span>
            </b-col>
            <b-col cols="4">
              <h4 class="text-primary-dark font-weight-600">{{ teacher.training_event | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Event') }}</span>
            </b-col>
            <b-col cols="4" class="px-0">
              <h4 class="text-primary-dark font-weight-600">{{ teacher.graduate | numeral('0,0') }}</h4>
              <span class="text-gray-light text-13">{{ $t('Graduates') }}</span>
            </b-col>
          </b-row> -->
          <b-row v-if="ownerTeacher" class="mt-3">
            <b-button v-if="ownerTeacher" v-b-modal.modal-update-teacher-profile @click="getFormdata" pill class="bth btn-danger-light font-weight-bold text-14 px-3"><b-icon icon="pencil" class="mr-2" variant="light"></b-icon>{{ $t('Edit Info') }}</b-button>
          </b-row>
          <b-row class="col-12 mt-4">
            <b-col cols="12" class="pl-0"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Status') }}</span></b-col>
            <div class="row mt-2 col-12">
              <div class="d-inline-flex mb-3" v-for="teacher_apply_type in teacher.teacher_apply_type" :key="teacher_apply_type.id">
                <div class="d-flex align-items-center justify-content-center pl-0 pr-0">
                  <b-avatar variant="light" size="3.0rem" :src="PUBLIC_PATH+'img/certificate/' + teacher_apply_type.teacher_apply_type_code + '.png'"></b-avatar>
                </div>
                <div  class="d-flex pl-2 pr-0">
                  <span class="small text-primary-dark list-style-type-none">{{ teacher_apply_type.teacher_apply_type_name }}</span>
                </div>
              </div>
            </div>
            <b-col cols="12" class="pl-0 mt-4"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Level of registration') }}</span></b-col>
            <ul class="pl-3 mt-3">
              <li class="small text-primary-dark mb-2" style="text-transform: capitalize;" v-for="teacher_level in teacher.teacher_level" :key="teacher_level.id">
                  {{ teacher_level.teacher_level_name }}
              </li>
            </ul>
          </b-row>
          <b-row class="mt-2">
            <template v-if="loggedIn">
              <b-col cols="12"><span class="text-primary-dark font-weight-bold content-title">{{ $t('Contact') }}</span></b-col>
              <b-col cols="12 mt-1">
                <a v-if="teacher.website_url" :href="teacher.website_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-link border-0 mt-2"><font-awesome-icon :icon="['fas', 'link']" /></b-button></a>
                <a v-if="teacher.facebook_url" :href="teacher.facebook_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-facebook border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'facebook-f']"/></b-button></a>
                <a v-if="teacher.instagram_url" :href="teacher.instagram_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-instragram border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'instagram']"/></b-button></a>
                <a v-if="teacher.twitter_url" :href="teacher.twitter_url" target="_blank"><b-button class="font-weight-bold rounded-circle btn-social-leftbar icon-twitter border-0 mt-2 ml-2"><font-awesome-icon :icon="['fab', 'twitter']"/></b-button></a>
              </b-col>
              <b-col cols="12" class="mt-3">
                <a :href="'tel:' + teacher.phone_number" class="small text-primary-dark d-block mb-3" v-if="teacher.phone_number"><b-icon icon="telephone-fill" /> {{ teacher.phone_number }}</a>
                <a :href="'mailto:' + teacher.email" class="small text-primary-dark d-block" v-if="teacher.email"><b-icon icon="envelope-fill" /> {{ teacher.email }}</a>
              </b-col>
            </template>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="12" ><span class="text-primary-dark font-weight-bold content-title">{{ $t('Location') }}</span></b-col>
            <b-col class="mt-3" v-if="(teacher.latitude && teacher.latitude) || (location.lat && location.lng )" cols="12 mt-2">
              <GmapMap
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false
                }"
                :center="{ lat: parseFloat(teacher.latitude || location.lat), lng: parseFloat(teacher.longitude || location.lng) }"
                :zoom="zoom"
                style="width:100%;  height: 200px;"
              >
              <GmapMarker
                :position="{ lat: parseFloat(teacher.latitude || location.lat), lng: parseFloat(teacher.longitude || location.lng) }"
                :clickable="true"
                @click="center={ lat: parseFloat(teacher.latitude || location.lat), lng: parseFloat(teacher.longitude || location.lng) }"
              ></GmapMarker>
              </GmapMap>
            </b-col>
            <b-col v-if="teacher.address || teacher.region" cols="12" class="mt-3">
              <b-row>
                <b-col class="" cols="2">
                  <span class="text-danger-light"><font-awesome-icon :icon="['fas', 'map-marker-alt']" /></span>
                </b-col>
                <b-col class="pl-0" cols="10">
                  <span class="text-13 text-primary-dark">{{ teacher.address || teacher.region }} </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="12" class="mt-1">
              <span class="text-primary-dark font-weight-bold content-title">{{ $t('Image Gallery') }}</span><br>
            </b-col>
            <b-col cols="12 pt-2 pr-2 mb-4">
              <b-row class="pr-3">
                <b-col cols="12">
                  <CoolLightBox
                    :items="teacherGallerySlide"
                    :index="index"
                    @on-open="closeModalPreviewImg"
                    @close="index = null">
                  </CoolLightBox>
                  <div class="images-wrapper row w-100">
                    <div class="col-4" v-for="(image, imageIndex) in teacherGallerySlide" :key="imageIndex">
                      <div
                        class="image"
                        @click="index = imageIndex"
                        :style="{ backgroundImage: 'url(' + image.thumb + ')'}"
                      >
                      <b-dropdown style="z-index:100" v-if="userProfile && userProfile.id == teacher.user" size="sm" variant="light" right no-caret class="btn-edit-gallery float-right p-0">
                        <template #button-content>
                          <b-icon icon="three-dots-vertical" font-scale="1" class="p-0"></b-icon>
                        </template>
                        <b-dropdown-item style="z-index:100" class="text-14" @click="setDataEditImg(image), $bvModal.show('modal-edit-img-gallery')"><b-icon icon="pencil" class="mt-n1 text-13"></b-icon> {{ $t('Edit') }}</b-dropdown-item>
                        <b-dropdown-item style="z-index:100" class="text-14" @click="onDeleteImgGallery(image), $bvModal.show('modal-delete-img-gallery')"><b-icon icon="trash" class="mt-n1 text-13"></b-icon> {{ $t('Delete') }}</b-dropdown-item>
                      </b-dropdown>
                      </div>
                    </div>
                  </div>
                </b-col>
                <div v-if="teacherGallerySlide.length == 0 && !userProfile" class="text-center col">
                  <span class="text-14">{{ $t('No results') }}</span>
                </div>
                <b-col v-if="userProfile && userProfile.id == teacher.user && teacherGallerySlide.length < 15" cols="4" class="pr-0">
                  <div v-b-modal.modal-add-img-gallery class="border border-1 rounded w-100 d-flex justify-content-center align-items-center add-img-gallery">
                    <b-icon scale="2" :title="$t('Add image gallery')" icon="plus"/>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="9" class="">
          <b-tabs
            content-class="pt-4"
            active-nav-item-class="font-weight-bold text-primary-dark border-2"
            nav-class="text-gray-light school-profile"
            lazy
            v-model="tabIndex"
            @activate-tab="eventActiveTab"
            >
            <b-tab>
              <template #title>
                <b-icon icon="person" :title="$t('Introduction')" class="d-md-none d-lg-none"/>
                <span class="d-none d-md-block d-lg-block">{{ $t('Introduction') }}</span>
              </template>
              <b-row>
                <b-col cols="12" lg="8" md="12">
                  <b-col cols="12" class="pb-4 d-block px-0 text-primary-dark">
                    <span class="font-weight-bold content-title">{{ $t('About me') }}</span><br>
                    <p class="mt-1 d-block text-gray-light" style="white-space: pre-line">{{ teacher.description }}</p>
                    <span class="mt-3 font-weight-bold">{{ $t('Style of Yoga') }}</span>
                    <span class="mt-1 d-block text-gray-light">
                      <span v-for="(style_of_yoga, index ) in teacher.style_of_yoga" :key="style_of_yoga.id">
                        <span v-if="index == teacher.style_of_yoga.length - 1">{{ style_of_yoga.style_of_yoga_name }}</span>
                        <span v-else>{{ style_of_yoga.style_of_yoga_name }}, </span>
                      </span>
                    </span>
                    <span class="mt-1 d-block text-gray-light">{{ teacher.style_of_yoga_name }}</span>
                    <span class="mt-1 d-block text-gray-light" style="white-space: pre-line">{{ teacher.description_style_of_yoga }}</span>
                    <span v-if="teacher.school" class="mt-3 d-block font-weight-bold">{{ $t('Other') }}</span>
                    <b-button v-if="teacher.school" pill variant="link" :to="{ name: 'schoolProfileDetail', params: { schoolId: school.id } }" class="font-weight-400 text-primary-light pl-0">{{ school.school_name }} ( WYA School ID: {{ school.school_id }} )</b-button>
                  </b-col>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template #title>
                <b-icon icon="calendar2-date" class="d-md-none d-lg-none"/>
                <span class="d-none d-md-block d-lg-block">{{ $t('Event') }}</span>
              </template>
              <div>
                <SchoolEvent :resultItems="events" :pastResultItems="pastEvents" type="teacher" :school="teacher" @onDeleteEvent="onDeleteEvent" @onChangeMonthEvent="onChangeMonthEvent" :isLoading="isLoadingEvent" />
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      </div>
    </b-container>
    <b-modal id="modal-update-teacher-profile" hide-footer centered size="lg" :title="$t('Edit Teacher Info')">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
            :name="$t('Name')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group>
            <label for="name" class="text-primary-dark font-weight-600">{{ $t('Name') }} <span class="text-danger-light">*</span></label>
            <b-form-input
              id="name"
              type="text"
              v-model="form.name"
              :placeholder="$t('Enter name')"
            >
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
           <validation-provider
            :name="$t('About me')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group>
            <label for="description" class="text-primary-dark font-weight-600">{{ $t('About me') }} <span class="text-danger-light">*</span></label>
            <b-form-textarea
              id="description"
              rows="4"
              max-rows="7"
              v-model="form.description"
              :placeholder="$t('About me')"
            >
            </b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <span class="text-primary-dark font-weight-600 text-18">{{ $t('Contact') }}</span>
            <b-row class="mt-2">
              <!-- <b-row> -->
                <b-col sm="12" lg="6">
                  <validation-provider
                    :name="$t('Email')"
                    rules="required|email"
                    autocomplete="off"
                    v-slot="{errors}"
                  >
                  <b-form-group>
                    <label for="email" class="text-primary-dark font-weight-600">{{ $t('Email') }} <span class="text-danger-light">*</span></label>
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      :placeholder="$t('Enter Email')"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col sm="12" lg="6">
                  <validation-provider
                    :name="$t('Phone number')"
                    rules="required"
                    autocomplete="off"
                    v-slot="{errors}"
                  >
                    <b-form-group>
                      <label for="phone" class="text-primary-dark font-weight-600">{{ $t('Phone number') }} <span class="text-danger-light">*</span></label>
                      <b-form-input
                        id="phone"
                        rules="required"
                        v-model="form.phone_number"
                        :placeholder="$t('Enter Phone number')"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              <!-- </b-row> -->
              <b-form-group class="col-lg-6">
                <label for="website" class="text-primary-dark font-weight-600">{{ $t('Website') }}</label>
                <b-form-input
                  id="website"
                  v-model="form.website_url"
                  :placeholder="$t('Paste your link here')"
                ></b-form-input>
              </b-form-group>
              <b-form-group class="col-lg-6">
                <label for="facebook_URL" class="text-primary-dark font-weight-600">{{ $t('Facebook URL') }}</label>
                <b-form-input
                  id="facebook_URL"
                  v-model="form.facebook_url"
                  :placeholder="$t('Paste your link here')"
                ></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-lg-6">
                <label for="twitter_URL" class="text-primary-dark font-weight-600">{{ $t('Twitter URL') }}</label>
                <b-form-input
                  id="twitter_URL"
                  v-model="form.twitter_url"
                  :placeholder="$t('Paste your link here')"
                ></b-form-input>
              </b-form-group>
              <b-form-group class="col-lg-6">
                <label for="instagram_URL" class="text-primary-dark font-weight-600">{{ $t('Instagram URL') }}</label>
                <b-form-input
                  id="instagram_URL"
                  v-model="form.instagram_url"
                  :placeholder="$t('Paste your link here')"
                ></b-form-input>
              </b-form-group>
          </b-row>
          <span class="text-primary-dark font-weight-600 text-18">{{ $t('Location') }}</span>
          <validation-provider
            :name="$t('Address')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="mt-2">
            <label for="address" class="text-primary-dark font-weight-600">{{ $t('Address') }} <span class="text-danger-light">*</span></label>
            <b-form-input
              id="address"
              v-model="form.address"
              :placeholder="$t('Enter Address')"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('Location')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group>
            <label for="location" class="text-primary-dark font-weight-600">{{ $t('Location') }} <span class="text-danger-light">*</span></label>
            <v-select id="location" class="form-v-selects" :placeholder="$t('Province or States')" label="text" :filterable="false" :options="regions" v-model="selectedRegion" @search="searchRegion">
              <template #search="{attributes, events}">
                <input
                  class="vs__search 11"
                  v-bind="attributes"
                  v-on="events"
                  @click="setReadOnly"
                />
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  {{ $t('No results found for') }} <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>{{ $t('Typing to search for a Province or States') }}</em>
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.text }}
                  </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.text }}
                </div>
              </template>
            </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <div class="w-100 justify-content-center d-flex">
            <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-update-teacher-profile')">
              {{ $t('Cancel') }}
            </b-button>
            <b-button pill type="submit" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark">
              <b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>
              {{ $t('Save') }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <b-modal ref="modal-add-img-gallery" id="modal-add-img-gallery" hide-footer body-class="pt-0"  :title="$t('Add Image Gallery')">
      <ValidationObserver ref="formGallery" v-slot="{ handleSubmit }">
        <b-form ref="formImageGallery" @submit.stop.prevent="handleSubmit(onSubmitImageGallery)">
          <b-img v-bind="mainPropImage" v-if="imageGallery"  :src="imageGallery" fluid rounded alt="Image" style="max-width:460px"></b-img>
          <validation-provider
            :name="$t('Image')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >

          <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="image-gallery" :label="$t('Image')+'*'">
            <b-form-file
              v-model="formImageGallery.image"
              type="file"
              id="image-gallery"
              :placeholder="$t('Choose a file or drop it here')"
              :drop-placeholder="$t('Drop file here')"
              accept="image/*"
              @change="onImageGalleryChange"
            ></b-form-file>
            <small class="text-danger px-1">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('Image description')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="img-gallery-description" :label="$t('Image description')+'*'">
            <b-form-input id="img-gallery-description" type="text" v-model="formImageGallery.title" :placeholder="$t('Image description')"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <div class="w-100 justify-content-center d-flex">
            <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-add-img-gallery'), resetFormImgGallery()">
              {{ $t('Cancel') }}
            </b-button>
            <b-button pill type="submit" :disabled="isLoadingImageGallery" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark">
              <b-spinner small class="mr-3" v-if="isLoadingImageGallery"  label="Loading..."/>
              {{ $t('Save') }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
     <b-modal ref="modal-edit-img-gallery" id="modal-edit-img-gallery" @hidden="resetDataEditImg()" hide-footer centered :title="$t('Edit Image')">
       <ValidationObserver ref="formEditGallery" v-slot="{ handleSubmit }">
        <b-form ref="formImageGallery" @submit.stop.prevent="handleSubmit(onSubmitEditImageGallery)">
          <b-img v-bind="mainPropImage" v-if="editImageGallery"  :src="editImageGallery" fluid rounded alt="Image" style="max-width:466px"></b-img>
          <validation-provider
            :name="$t('Image')"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="image-gallery" :label="$t('Image')">
            <b-form-file
              v-model="formEditImageGallery.image"
              type="file"
              id="image-gallery"
              :placeholder="$t('Choose a file or drop it here')"
              :drop-placeholder="$t('Drop file here')"
              accept="image/*"
              @change="onEditImageGalleryChange"
            ></b-form-file>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('Image description')"
            rules="required"
            autocomplete="off"
            v-slot="{errors}"
          >
          <b-form-group class="d-inline col-12 pl-0 font-weight-bold text-primary-dark" for="img-gallery-description" :label="$t('Image description')+'*'">
            <b-form-input id="img-gallery-description" type="text" v-model="formEditImageGallery.title" :placeholder="$t('Image description')"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
          </validation-provider>
          <div class="w-100 justify-content-center d-flex">
            <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-edit-img-gallery'), resetFormImgGallery()">
              {{ $t('Cancel') }}
            </b-button>
            <b-button pill type="submit" :disabled="isLoadingEditImageGallery" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark">
              <b-spinner small class="mr-3" v-if="isLoadingEditImageGallery"  label="Loading..."/>
              {{ $t('Save') }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <b-modal ref="modal-delete-img-gallery" id="modal-delete-img-gallery" @hidden="setDataDeleteImg()" hide-footer centered :title="$t('Delete Image')">
      <div class="text-center">
        <b-img rounded :src="deleteImgSrc" /><br>
        <p class="mt-2">{{ $t('Do you want to delete image {name}', { name: deleteImgTitle }) }}</p>
      </div>
      <div class="w-100 justify-content-center d-flex">
        <b-button pill class="mt-2 px-5 btn-light font-weight-bold d-inline" @click="$bvModal.hide('modal-delete-img-gallery')">
          {{ $t('Cancel') }}
        </b-button>
        <b-button pill type="submit" class="d-inline ml-3 mt-2 px-5 font-weight-bold btn-primary-dark" @click="onSubmitDeleteImg">
          <b-spinner small class="mr-3" v-if="isLoadingDeleteImg"  label="Loading..."/>
          {{ $t('Delete') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SchoolEvent from '../../components/school/SchoolEvent.vue'
import Api from '../../api/api'
import 'vue-select/dist/vue-select.css'
import AvatarCropper from 'vue-avatar-cropper'
import SocialSharing from '../../components/SocialSharing.vue'
import UpcomingEvents from '../../components/UpcomingEvents.vue'

const axios = require('axios')

export default {
  name: 'TeacherProfile',
  title: ' | Teacher profile',
  components: {
    SchoolEvent,
    AvatarCropper,
    SocialSharing,
    UpcomingEvents,
  },
  data () {
    return {
      zoom: 15,
      tabIndex: 0,
      loading: true,
      activeClassCover: 'bth d-block float-right mr-lg-3 mt-n5 btn-danger-light font-weight-bold text-12',
      defaultClassCover: 'bth d-none float-right mr-lg-3 mt-n5 btn-danger-light font-weight-bold text-12',
      activeClassAvatar: 'rounded-circle i-con-avatar btn-danger-light p-2',
      defaultClassAvatar: 'rounded-circle i-con-avatar btn-danger-light p-2 d-none',
      ownerTeacher: false,
      ownerTeacherId: null,
      viewTeacherId: null,
      teacher: {},
      form: {
        id: null,
        name: '',
        description: '',
        email: '',
        phone_number: '',
        website_url: '',
        facebook_url: '',
        twitter_url: '',
        instagram_url: '',
        address: '',
        region: ''
      },
      selectedRegion: null,
      regions: [],
      schoolFormData: {
        school_levels: [],
        school_level_additionals: []
      },
      comments: [],
      commentPage: 1,
      myComment: {
        rating: 0,
        comment: null,
        school: null,
        user: null
      },
      commentLimitPerPage: 5,
      commentNextPage: null,
      ratingCommentSummary: null,
      teacherGalleries: [],
      teacherGallerySlide: [],
      schoolGraduateds: [],
      schoolGraduatedIntro: [],
      isLoadingFormAddGraduate: false,
      closeModalFormAddGraduate: false,
      events: [],
      eventsIntro: [],
      pastEvents: [],
      isLoadingGraduate: false,
      isLoadingComment: false,
      isLoadingEvent: false,
      isLoading: false,
      urlUploadAvatar: null,
      urlUploadCover: null,
      uploadHeader: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      options: {
        responsive: [
          { end: 576, size: 3 },
          { start: 576, end: 768, size: 2 },
          { start: 768, end: 992, size: 3 },
          { start: 992, end: 1200, size: 4 },
          { start: 1200, size: 5 }
        ],
        list: {
          windowed: 1200,
          padding: 10
        }
      },
      filter: {},
      mainPropImage: {
        blankColor: '#bbb',
        width: 960,
        height: 540,
        class: 'my-2'
      },
      imageGallery: '',
      formImageGallery: {
        teacher: null,
        image: null,
        title: null,
        is_active: true
      },
      formEditImageGallery: {
        id: null,
        teacher: null,
        image: null,
        title: null,
        is_active: true
      },
      isLoadingImageGallery: false,
      isLoadingEditImageGallery: false,
      location: {},
      school: {},
      index: null,
      indexImg1: null,
      isLoadingDeleteImg: false,
      deleteImgTitle: '',
      deleteImgId: null,
      deleteImgSrc: null
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    },
    teacherId () {
      return this.$store.getters.teacherId
    },
    currentLanguage () {
      return this.$store.getters.currentLanguage
    },
    loggedIn () {
      return this.$store.getters.loggedIn
    }
  },
  watch: {
    currentLanguage (newValue, oldValue) {
      this.getTeacherDetail()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.urlUploadAvatar = process.env.VUE_APP_API_ENDPOINT + '/api/teacher/teacher/' + this.teacherId + '/doc_avatar'
    this.urlUploadCover = process.env.VUE_APP_API_ENDPOINT + '/api/teacher/teacher/' + this.teacherId + '/doc_cover'
    this.viewTeacherId = this.$route.params.teacherId ? this.$route.params.teacherId : null
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    const teacherId = localStorage.getItem('teacherId')
    if (teacherId === null && this.viewTeacherId === null && this.userProfile) {
      this.$router.push({ name: 'teacherRegisterInformation' })
    } else {
      if (teacherId && this.viewTeacherId === null) {
        this.ownerTeacherId = teacherId
      } else {
        this.ownerTeacherId = this.viewTeacherId
      }
    }
    this.getTeacherDetail()
    this.teacherGallery()
    var filterDate = new Date()
    var firstDay = filterDate
    var lastDay = new Date(filterDate.getFullYear(), filterDate.getMonth() + 1, 0)
    this.filter = {
      start_date__gte: this.$options.filters.dateFormat(firstDay, 'YYYY-MM-DD'),
      end_date__lte: this.$options.filters.dateFormat(lastDay, 'YYYY-MM-DD')
    }
  },
  methods: {
    onDeleteImgGallery (img) {
      this.deleteImgSrc = img.thumb
      this.deleteImgTitle = img.description
      this.deleteImgId = img.id
    },
    setDataDeleteImg () {
      this.deleteImgSrc = null
      this.deleteImgTitle = null
      this.deleteImgId = null
    },
    resetDataEditImg () {
      this.formEditImageGallery.id = null
      this.editImageGallery = null
      this.formEditImageGallery.title = null
      this.formEditImageGallery.is_active = null
    },
    setDataEditImg (img) {
      this.formEditImageGallery.id = img.id
      this.formEditImageGallery.school = this.schoolId
      this.editImageGallery = img.src
      this.formEditImageGallery.title = img.description
      this.formEditImageGallery.is_active = true
    },
    closeModalPreviewImg () {
      if (this.deleteImgId != null || this.formEditImageGallery.id) {
        setTimeout(function () { document.querySelector('.cool-lightbox').click() }, 50)
      }
    },
    onSubmitDeleteImg () {
      this.deleteImgGallery(this.deleteImgId)
    },
    async geocodeAddress () {
      let address = null
      if (this.teacher.address) {
        address = this.teacher.address
      } else if (this.teacher.region_name) {
        address = this.teacher.region_name
      }
      if (address) {
        try {
          const teacherAddress = encodeURI(address)
          const { data } = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${teacherAddress}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
          )
          const geocodedLocation = data.results[0]
          this.location = {
            lat: geocodedLocation.geometry.location.lat,
            lng: geocodedLocation.geometry.location.lng
          }
        } catch (e) {
        }
      }
    },
    eventActiveTab (e) {
      if (e === 1) {
        this.eventList(this.filter)
      }
    },
    setReadOnly (el) {
      setTimeout(() => {
        el.target.removeAttribute('readonly')
      }, 80)
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    getTeacherDetail () {
      Api.teacherDetail({ id: this.ownerTeacherId })
        .then((response) => {
          this.teacher = response.data
          if ((this.teacher.latitude === null || this.teacher.latitude === undefined) || (this.teacher.longitude === null || this.teacher.longitude === undefined)) {
            this.geocodeAddress()
          }
          if (this.teacher.status === 1 && this.userProfile.id === response.data.user) {
            this.$router.push({ name: 'teacherRegisterInformation' })
          } else if (this.teacher.status === 1 && this.userProfile.id !== response.data.user) {
            this.$router.push({ name: 'searchDirectory' })
          }
          // set form data for update
          if (this.userProfile && this.userProfile.id === response.data.user) {
            this.ownerTeacher = true
            for (const property in this.form) {
              if (property in response.data) {
                this.form[property] = response.data[property]
              }
              this.selectedRegion = { value: response.data.region, text: response.data.region_name }
            }
          } else {
            this.ownerTeacher = false
          }
          if (this.teacher.school) {
            this.getSchoolDetail(this.teacher.school)
          }
          this.loading = false
        })
        .catch((error) => {
          let messageError = ''
          if (error.response) {
            messageError = this.$t('Teacher not found')
          } else {
            messageError = this.$t('Something wrong please try again')
          }
          this.$notify({
            group: 'error',
            title: '',
            text: messageError
          })
          this.loading = false
        })
    },
    getSchoolDetail (schoolId) {
      Api.getSchool({ schoolId: schoolId })
        .then((response) => {
          this.school = response.data
        })
        .catch((error) => {
          let messageError = ''
          if (error.response.status) {
            messageError = this.$t('Yoga school not found')
          } else {
            messageError = this.$t('Something wrong please try again')
          }
          this.$notify({
            group: 'error',
            title: '',
            text: messageError
          })
        })
    },
    submitForm () {
      this.$refs.form.submit()
    },
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.updateTeacher()
        }
      })
    },
    updateTeacher () {
      this.isLoading = true
      this.form.region = this.selectedRegion.value
      Api.updateTeacher(this.form)
        .then((response) => {
          this.isLoading = false
          this.$notify({
            group: 'success',
            title: this.$t('Update teacher'),
            text: this.$t('Successfully saved data')
          })
          this.$bvModal.hide('modal-update-teacher-profile')
          this.getTeacherDetail()
        })
        .catch((error) => {
          this.isLoading = false
          const responseError = error.response
          let textError = ''
          if (responseError) {
            textError = Object.values(responseError.data).join()
          } else {
            textError = this.$t('Unsuccessfully saved data')
          }
          this.$notify({
            group: 'error',
            title: this.$t('Update yoga school'),
            text: textError
          })
        })
    },
    searchRegion (searchText, loading) {
      this.searchText = searchText
      if (this.searchText) {
        this.regions = []
        loading(true)
        Api.searchRegion({ q: this.searchText }).then(response => {
          if (response.data.results) {
            response.data.results.forEach(region => {
              const url = region.url.split('/')
              this.regions.push({ value: url[url.length - 2], text: region.display_name })
            })
          }
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    },
    getFormdata () {
      Api.shoolFormData().then(response => {
        if (response.data) {
          this.schoolFormData = response.data
        }
      })
    },
    handleUploading (form, xhr) {
      form.append('via', 'request')
    },
    handleCompleted (response, form, xhr) {
      if (response) {
        this.getTeacherDetail()
        this.$notify({
          group: 'success',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar successful')
        })
      } else {
        this.$notify({
          group: 'error',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar unsuccessful')
        })
      }
    },
    handlerError (message, type, xhr) {
      if (type === 'upload') {
        this.$notify({
          group: 'error',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar unsuccessful')
        })
      }
    },
    teacherGallery () {
      Api.teacherGallery({ teacher: this.ownerTeacherId }).then(response => {
        if (response.data.results) {
          this.teacherGalleries = response.data.results
          this.teacherGallerySlide = []
          this.teacherGalleries.forEach(img => {
            const slide = {
              id: img.id,
              src: img.image,
              thumb: img.image_small,
              description: img.title
            }
            this.teacherGallerySlide.push(slide)
          })
        }
      }).catch(() => {})
    },
    eventList (filter, tap) {
      this.isLoadingEvent = true
      var data = {
        teacher: this.ownerTeacherId
      }
      if (filter) {
        data = { ...data, ...filter }
      }
      Api.event(data).then(response => {
        if (response.data.results) {
          this.events = response.data.results
          this.isLoadingEvent = false
        }
      }).catch(() => {
        this.isLoadingEvent = false
      })
    },
    onChangeMonthEvent (dataFilter) {
      const filterDate = new Date(dataFilter.date)
      const currentDate = new Date()
      var firstDay = null
      if (currentDate.getMonth() === filterDate.getMonth()) {
        firstDay = new Date()
      } else {
        firstDay = new Date(filterDate.getFullYear(), filterDate.getMonth(), 1)
      }
      var lastDay = new Date(filterDate.getFullYear(), filterDate.getMonth() + 1, 0)
      const filter = {
        start_date__gte: this.$options.filters.dateFormat(firstDay, 'YYYY-MM-DD'),
        end_date__lte: this.$options.filters.dateFormat(lastDay, 'YYYY-MM-DD')
      }
      this.eventList(filter)
    },
    onImageGalleryChange (event) {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.imageGallery = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onEditImageGalleryChange (event) {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          if (e) {
            this.editImageGallery = e.target.result
          }
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onSubmitImageGallery () {
      this.$refs.formGallery.validate().then(result => {
        if (result) {
          this.formImageGallery.teacher = this.ownerTeacherId
          Api.addImageTeacherGallery(this.formImageGallery).then(response => {
            if (response.data) {
              this.teacherGalleries.push(response.data)
              const slide = {
                id: response.data.id,
                src: response.data.image,
                thumb: response.data.image_small,
                description: response.data.title
              }
              this.resetFormImgGallery()
              this.teacherGallerySlide.push(slide)
              this.$notify({
                group: 'success',
                title: this.$t('Add Image Gallery'),
                text: this.$t('Successfully saved data')
              })
            }
            this.$refs['modal-add-img-gallery'].hide()
          }).catch(() => {
            this.$notify({
              group: 'error',
              title: this.$t('Add Image Gallery'),
              text: this.$t('Unsuccessfully saved data')
            })
            this.$refs['modal-add-img-gallery'].hide()
          })
        }
      })
    },
    onSubmitEditImageGallery () {
      this.$refs.formEditGallery.validate().then(result => {
        if (result) {
          this.isLoadingEditImageGallery = true
          this.formEditImageGallery.teacher = this.ownerTeacherId
          Api.updateTeacherGallery(this.formEditImageGallery).then(response => {
            this.teacherGallery()
            if (response.data) {
              this.$notify({
                group: 'success',
                title: this.$t('Update Image Gallery'),
                text: this.$t('Successfully update data')
              })
            }
            this.isLoadingEditImageGallery = false
            this.$refs['modal-edit-img-gallery'].hide()
          }).catch(() => {
            this.isLoadingEditImageGallery = false
            this.$notify({
              group: 'error',
              title: this.$t('Update Image Gallery'),
              text: this.$t('Unsuccessfully update data')
            })
            this.$refs['modal-add-img-gallery'].hide()
          })
        }
      })
    },
    deleteImgGallery (id) {
      Api.deleteTeacherGallery({ id: id }).then(response => {
        this.teacherGallery()
        this.$notify({
          group: 'success',
          title: this.$t('Delete Image'),
          text: this.$t('Successfully delete data')
        })
      }).catch(() => {
        this.$notify({
          group: 'error',
          title: this.$t('Delete Image'),
          text: this.$t('Unsuccessfully delete data')
        })
      }).finally(() => {
        this.$bvModal.hide('modal-delete-img-gallery')
      })
    },
    resetFormImgGallery () {
      this.imageGallery = null
      this.$nextTick(() => {
        this.formImageGallery.image = null
        this.formImageGallery.title = null
        this.$refs.formImageGallery.reset()
      })
    },
    onDeleteEvent (form) {
      Api.deleteEvent(form).then(response => {
        if (form.isPast) {
          const index = this.pastEvents.findIndex((event) => event.id === form.id)
          this.pastEvents.splice(index, 1)
        } else {
          const index = this.events.findIndex((event) => event.id === form.id)
          this.events.splice(index, 1)
        }
        this.$notify({
          group: 'success',
          title: this.$t('Delete event'),
          text: this.$t('Successfully delete data')
        })
      }).catch(() => {
        this.$notify({
          group: 'error',
          title: this.$t('Delete event'),
          text: this.$t('Unsuccessfully delete data')
        })
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style >
  .profile-cover-image {
    object-fit: cover;
  }
  .btn-social-leftbar {
    height:40px;
    width:40px;
  }
  .icon-link {
    background-color:#841F26;
  }
  .icon-facebook {
    background-color:#3b5998;
  }
  .icon-instragram {
    background-color:#3f729b;
  }
  .icon-twitter {
    background-color: rgb(83 167 232);
  }
  .box-graduate {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 10px #0000000D;
    border-radius: 10px;
  }
  .img-wrapper{
    position: relative;
    overflow:hidden;
    height:249px;
    width: 166px;
  }
  .img-wrapper img{
      position: absolute;
      top:-100%; left:0; right: 0; bottom:-100%;
      margin: auto;
  }
  .img-wrapper .overlay-text {
    position: absolute;
    background: transparent linear-gradient(rgba(255, 255, 255, 0.007), rgba(0, 0, 0, 0.719));
    height: 68px;
    width: 100%;
    bottom: 0;
  }
  .school-profile .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: #ffffff #ffffff #F04B4C;
  }
  .school-profile .nav-link {
    border: 2px solid transparent;
    color: #9A9A9A;
    font-weight: bold;
  }
  .btn-img-edit-profile {
    font-size: 2.5rem;
    float: right;
    position: absolute;
    margin-left: 40px;
  }
  .btn-img-edit-cover-school {
    width: 100%;
    margin-top: 260px;
    font-size: 2.5rem;
    position: absolute;
    margin-right: 0px;
  }
  .btn-img-edit-cover-school button {
    float: right;
    margin-right: 20px;
  }
  .btn-img-edit-profile:hover, .i-con-avatar:hover, .add-img-gallery:hover, .img-school-gallery:hover{
    cursor: pointer;
  }
  .add-img-gallery{
    background-color: #FAFAFA;
    border: 1px solid #DBDBDB;
    height: auto;
    max-height: 345px !important;
  }
  .modal-header, .modal-footer {
    border-bottom: 0px !important;
  }
  .modal-footer {
    border-top: 0px !important;
  }
  .v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
  }

  .v-select .dropdown li:last-child {
    border-bottom: none;
  }

  .v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
  }

  .v-select .dropdown-menu .active > a {
    color: #fff;
  }
  .vs__dropdown-menu > li {
    padding: 5px !important;
    padding-left: 7px !important;
    border-bottom: 1px solid #f6f8fa;
  }
  .avatar-cropper-btn:hover {
    background-color: #3B6098 !important;
  }
  .pswp__caption__center {
    text-align: center !important;
  }
  .my-gallery {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .my-gallery figure{
    margin: 0px 0px 15px 15px !important;
  }
  .my-gallery img{
    width: 77px;
    height: 77px;
    border-radius: 5px;
  }
  .item-graduate {
    background-color: #F5F7FA;
    min-height: 165px;
  }
  .social-graduate {
    height: 37px;
    width: 37px;
    background-color: #e4ebf7;
  }
  .btn-edit-graduate {
    float: right;
    right: 0;
    position: absolute;
  }
  .image {
    width: 77px;
    height: 77px;
    border-radius: 5px;
    margin: 0px 0px 5px 0px !important;
    background-position: center !important;
  }
  .btn-edit-gallery button {
    padding: 0px;
  }
</style>
